<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
        <tr style="background: rgba(230, 236, 238, 0.46);">
          <td>
            <span>Dimension:</span> (90mm*64mm*27mm(3.54in*2.52in/81.06in)
          </td>
        </tr>
        <tr>
          <td><span>Temperature Range:</span> -40°C to +125°C</td>
        </tr>
        <tr style="background: rgba(230, 236, 238, 0.46);">
          <td>
            <span>Measure Range of Humidity Sensor: </span> 0%RH to 100%RH
          </td>
        </tr>
        <tr >
          <td><span>Battery Runtime:</span> Up to +1 Year</td>
        </tr>
        <tr style="background: rgba(230, 236, 238, 0.46);">
          <td><span>GSM Chip:</span> SIMCOM</td>
        </tr>
        <tr>
          <td><span>GPS Antenna:</span> Built-in</td>
        </tr>
        <tr style="background: rgba(230, 236, 238, 0.46);">
          <td><span>Flash Memory: </span> Upto 4320pcs reading</td>
        </tr>
        <tr >
          <td><span>Water proof level:</span> Ip66</td>
        </tr>
        <tr style="background: rgba(230, 236, 238, 0.46);">
          <td><span>Led Indicator: </span> Status and charge</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'Table1',
};
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
}
td {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  /* identical to box height, or 29px */

  /* Text */

  color: #212529;
}
</style>
